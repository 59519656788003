import { CSSProperties } from "react";
import styles from "./small_button.module.css";

type TSmallBtnProps = {
	text: string;
	emoji?: string;
	onClick: () => void;
	style?: CSSProperties;
};

export function SmallBtn({ text, onClick, emoji, style }: TSmallBtnProps) {
	return (
		<button style={style} onClick={onClick} className={styles.small_btn}>
			{emoji != null && <span className="inter700 sp24">{emoji}</span>}
			<p className="inter700 sp16" style={{ color: "inherit" }}>
				{text}
			</p>
		</button>
	);
}
